import { Alert, AlertDescription, AlertIcon, AlertTitle, Flex } from "@chakra-ui/react";
import { useLocation } from "@reach/router";
import React from "react";

import { $tounsigned } from "foundation-ts/commons";

const ResultExternalSignPage = () => {
    const { search } = useLocation() ;
    const searchParams = new URLSearchParams(search) ;
    const result = searchParams.get("ret") ;

    return (
        <Flex width="100%" justifyContent="space-around">
            <Alert variant='subtle' flexDirection='column' alignItems='center' justifyContent='center' textAlign='center' height='200px' width="20%" mt="20">
                <AlertTitle mt={4} mb={1} fontSize='lg'>
                    {$tounsigned(result) === 0  ? "Refus de signer" : "Validation de votre signature"}
                </AlertTitle>
                <AlertDescription maxWidth='sm'>
                    {$tounsigned(result) === 0  ? "Nous avons bien pris en compte votre refus de signer le(s) document(s). Vous recevrez bientôt un mail de confirmation." : "Les documents ont bien été signés. Lorsque tous les participants les auront, à leur tour, signés. Vous recevrez un mail vous permettant de les récupérer."}
                </AlertDescription>
            </Alert>
        </Flex>
    ) ;
}

export default ResultExternalSignPage ;